import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ClientSelect } from '@components/client-select'
import { ClientUser } from '@models/clients'
import { useModal } from '@components/modals/use-modal'
import { FormInput } from '@hyper/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import {
  EndlessHolidayCreateFormInputs,
  EndlessHolidayCreateIndividualEmailKey,
} from '@modules/endless-holiday/invites/create/modal'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'

interface Props {
  initialClient: ClientUser | undefined
}

export const EndlessHolidayCreateFormClientData = ({ initialClient }: Props) => {
  const { product_package_sets } = useProductsAppData()
  const [client, setClient] = React.useState<ClientUser | undefined>(initialClient)
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const { formState, setValue, control } = useFormContext<EndlessHolidayCreateFormInputs>()

  const individualEmail = useWatch({ control, name: EndlessHolidayCreateIndividualEmailKey })

  React.useEffect(() => {
    setValue('client', client)
  }, [client])

  const availableSets = product_package_sets.map(({ id, name }) => createSelectOption(name, id))
  const sellers = useGroupedSellers()
  const sources = useGroupedSources()

  return (
    <div className="px-1">
      <ClientSelect
        label={<span className="text-secondary mt-0 fw-semi-bold">Wyszukaj klienta</span>}
        setClient={setClient}
        client={client}
        handleToggleClientDialog={handleToggleClientDialog}
        error={formState.errors.client}
        colSize={8}
        formGroupClassName="px-0"
        isDisabled={!!individualEmail}
      />

      <FormInput
        disabled={!!client}
        colSize={8}
        name={EndlessHolidayCreateIndividualEmailKey}
        formGroupClassName="mb-0 "
        placeholder="Wpisz adres e-mail"
        label={<span className="fw-semi-bold">lub wpisz nowy adres e-mail</span>}
      />
      <div className="mx-n1">
        <div className="dropdown-divider mb-0 mt-3" />
        <div className="bg-grey py-3 px-3">
          <div className="row align-items-center">
            <strong className="col-3">Wybierz zestaw</strong>
            <div className="col-9">
              <FormSelect
                options={availableSets}
                name="product_package_set"
                formPlainProps={{ colSize: 9, colClassName: 'px-0', formGroupClassName: 'mb-0' }}
              />
            </div>
          </div>

          <div className="row align-items-center mt-2">
            <strong className="col-3">Sprzedawca</strong>
            <div className="col-9">
              <FormSelect
                selectProps={{ isClearable: true }}
                options={sellers}
                name="seller"
                formPlainProps={{ colSize: 9, colClassName: 'px-0', formGroupClassName: 'mb-0' }}
              />
            </div>
          </div>

          <div className="row align-items-center mt-2">
            <strong className="col-3">Źródło marketingowe</strong>
            <div className="col-9">
              <FormSelect
                selectProps={{ isClearable: true }}
                options={sources}
                name="source_marketing"
                formPlainProps={{ colSize: 9, colClassName: 'px-0', formGroupClassName: 'mb-0' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
